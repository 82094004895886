export default [{
    title: 'Reportes',
    icon: 'PieChartIcon',
    children: [
        {
            title: 'Fondo de Garantía',
            route: 'reports-guarantee-fund',
            action: 'read',
		    resource: 'reports'
        }
    ]
}];