export default [{
    title: 'Verificar',
    icon: 'CheckCircleIcon',
    children: [
        {
            title: 'Lista',
            route: 'verify-list',
            action: 'approve',
		    resource: 'verify'
        }
    ]
}];