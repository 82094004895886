export default [{
    title: 'Proveedores',
    icon: 'BriefcaseIcon',
    children: [
        {
            title: 'Lista',
            route: 'providers-list',
            action: 'read',
		    resource: 'providers'
        }, 
        {
            title: 'Agregar',
            route: 'providers-create',
            action: 'create',
		    resource: 'providers'
        }
    ]
}];